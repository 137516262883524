@import "node_modules/uikit/src/less/uikit";
@import "node_modules/uikit/src/less/components/notify";
@import "node_modules/uikit/src/less/components/slidenav";
@import "node_modules/uikit/src/less/components/sortable";

@import "variables";

body {
  font-family: 'Open Sans',sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;

  color: #666;
}

body > .uk-navbar {
  background: rgb(245,93,78);
  background: -moz-linear-gradient(top, rgba(245,93,78,1) 0%, rgba(218,62,47,1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(245,93,78,1)), color-stop(100%,rgba(218,62,47,1)));
  background: -webkit-linear-gradient(top, rgba(245,93,78,1) 0%,rgba(218,62,47,1) 100%);
  background: -o-linear-gradient(top, rgba(245,93,78,1) 0%,rgba(218,62,47,1) 100%);
  background: -ms-linear-gradient(top, rgba(245,93,78,1) 0%,rgba(218,62,47,1) 100%);
  background: linear-gradient(to bottom, rgba(245,93,78,1) 0%,rgba(218,62,47,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f55d4e', endColorstr='#da3e2f',GradientType=0 );
  margin-bottom: @grid-gutter-vertical;

  i.fa {
    margin-right: 5px;
  }
}

.uk-navbar-brand img {
  max-height: 30px;
}

.block {
  padding: 20px;

  &.white {
    background: white;
  }
}

.mainBlock, #applicationErrors {
  padding: 0 @grid-gutter-horizontal;
}




h1, h2, h3, h4, h5, h6 {
  color: #e44046;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}
h1 {
  font-size: 36px;
}


div.dataTables_wrapper div.dataTables_processing
{
  margin: 0px !important;
  background: white;
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
}


@media (max-width: @breakpoint-small-max) {
  aside.sidebar{
    margin-bottom: @grid-gutter-vertical;
  }
}

@media (min-width: @breakpoint-xlarge) {
  body > .uk-navbar {
    margin-bottom: @grid-gutter-large-vertical;
  }
  .mainBlock, #applicationErrors {
    padding: 0 @grid-gutter-large-horizontal;
  }
}

form.droppable {
  margin-top: 50px;
  padding: 50px 20px;
  text-align: center;
  outline: 2px dashed #92b0b3;
  outline-offset: 0px;
  -webkit-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;
  background-color: white;

  &.is-dragover {
    background-color: rgba(146, 176, 179, 0.13);
  }
  &.enabled {
    .file {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      & + label {
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
      }
    }
  }
}

.dt-merge-grid > .uk-width-1-1
{
  overflow: scroll;
}

label.uk-form-label
{
  font-weight:bold;
}
.images
{
  font-size: 0px;
  text-align: center;
  position: absolute;
  padding-bottom: 145px;
  margin-top: 20px;

  .frame {
    box-sizing: content-box;
    height: 160px; /*can be anything*/
    width: 160px; /*can be anything*/
    position: relative;
    margin: 10px;
    background-color: #fff;
    float: left;
    border: 1px solid #92b0b3;


    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .caption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      color: white;
      background: rgba(0,0,0,0.4);
      font-size: 12px;
      z-index: 1;
    }
    .hover {
      position: absolute;
      display: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0px;
      z-index: 2;
      font-size: 12px;
      text-align: right;
      padding: 10px;
    }

    &:hover .hover
    {
      display: block;
    }
  }

}

.sortableField {
  background: #ededed;
  padding: 10px 15px;
  margin-bottom: 5px;

  .handler & {
    cursor: move;
  }

  &.disabled {
    background: #f7f7f7;
    color: #AAA;
  }
}

.datasheet-steps {

  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;
  
  form {
    display: none;
  }
  
  li {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;


    & + li {
      border-top: none;
    }

    &:hover {
      background: #f9f9f9;
    }
    &.disabled {
      cursor: not-allowed;
    }

    .rightsmall {
      font-style: italic;
      font-size: 0.8em;
      float: right;
      margin-right: 8px;
      line-height: 24px;
    }

    i
    {
      border-radius: 100%;
      padding: 5px;
      color: white;
      background: #eee;
      width: 1em;
      text-align: center;
      margin-right: 5px;
      
      &.current {
        background: rgb(9,176,221);
      }

      &.done {
        background: rgb(40,198,51);
      }
    }

  }
}

.datasheet-options {

  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;

  li {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 10px;
    cursor: pointer;
    min-height: 35px;


    & + li {
      border-top: none;
    }

    &:hover {
      background: #f9f9f9;
    }


    .datasheet_item_title {
      display: block;
      font-weight: 600;
      font-size: 1em;
      color: #555;
    }
    .datasheet_item_description {
      display: block;
      font-size: 0.8em;
      color: #777;
    }

  }
}

.datasheet-official {

  .datasheet_authorized {
    font-size: 0.7em;
    display: block;
    text-align: center;
    margin-bottom: 1.2em;
  }
  h1.datasheet_article_title {
    margin: 0px;
    border-bottom: 1px solid #eee;
    padding: 20px 0px;
    color: #e44046;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: none !important;
  }
  .datasheet_info_left {
    width: 50%;
    float: left;
  }
  .datasheet_info_right {
    width: 50%;
    float: right;
    text-align: right;
  }
  .datasheet_drawing_image {
    max-width: 45%;
    float: right;
    margin-top: 20px
  }

}